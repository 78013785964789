<template>
  <div>
    <!-- <v-card class="mb-6 position-relative" outlined>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-fab-transition>
            <v-btn
              v-on="on"
              v-bind="attrs"
              absolute
              top
              right
              @click="resetFilter()"
              fab
              icon
              small
              ><v-icon>mdi-filter-off</v-icon></v-btn
            >
          </v-fab-transition>
        </template>
        <span>Сбросить фильтры</span>
      </v-tooltip>
      <user-filter-menu v-bind.sync="showFilters"></user-filter-menu>
      <v-row class="pt-2 mx-2">
        <v-col v-if="showFilters.agencies" cols="12" sm="6">
          <v-select
            dense
            outlined
            item-text="name"
            item-value="name"
            label="Агентства"
            hide-details
            multiple
            :items="agencies"
            v-model="filters.agencies"
          ></v-select>
        </v-col>
      </v-row>
    </v-card> -->

    <v-data-table
      :sort-by="'name'"
      :headers="headers"
      v-model="selected"
      :items="filteringUsers"
      :items-per-page="10"
      :search="search"
      fixed-header
      :loading="isLoading"
      show-select
      loader-height="6"
      loading-text="Загрузка списка... Пожалуйста подождите "
      class="elevation-3"
      :footer-props="footerProps"
    >
      <template v-slot:top>
        <mass-update class="ma-0" :entity-list.sync="selected"></mass-update>
      </template>
      <template #[`item.agencies`]="{ item }">
        <span v-if="item.agencies.length === 0">-</span>
        <div class="d-flex align-center" v-else>
          <span>{{ item.agencies[0].name }}</span>
          <popover
            v-if="item.agencies.length > 1"
            title="Агентства"
            :data="item"
            main-prop="agencies"
          ></popover>
        </div>
      </template>
      <template #[`item.clients`]="{ item }">
        <span v-if="item.clients.length === 0"> -</span>
        <div class="d-flex align-center" v-else>
          <span>{{ item.clients[0].name }}</span>
          <popover
            v-if="item.clients.length > 1"
            title="Клиенты"
            :data="item"
            main-prop="clients"
          ></popover>
        </div>
      </template>
      <template #[`item.email_verified_at`]="{ item }">
        <span v-if="!item.email_verified_at">Не верифицирован</span>
        <span v-else>{{
          new Date(item.email_verified_at).toLocaleString()
        }}</span>
      </template>
      <template #[`item.options`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-1"
              small
              icon
              fab
              @click="openDialog(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
          </template>
          <span>Редактировать пользователя</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-1"
              @click="changeStatus(item)"
              v-bind="attrs"
              v-on="on"
              small
              icon
              fab
            >
              <v-icon>{{ item.is_active ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
            </v-btn>
          </template>
          <span>Скрыть пользователя</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-1"
              @click="item.deleted_at ? returnUser(item) : removeUser(item)"
              v-bind="attrs"
              v-on="on"
              small
              icon
              fab
            >
              <v-icon>{{
                item.deleted_at ? "mdi-delete-restore" : "mdi-delete"
              }}</v-icon>
            </v-btn>
          </template>
          <span>{{
            item.deleted_at
              ? "Восстановить пользователя"
              : "Удалить пользователя"
          }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <user-popup
      :is-new="false"
      :entity="currentEntity"
      v-model="dialogState"
    ></user-popup>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { GET_AGENCIES } from "@/store/const/agencies";
import { GET_ROLES } from "@/store/const/roles";
import { GET_USERS, DELETE_USER, UPDATE_USER } from "@/store/const/users";
import requestControl from "@/mixins/requestControl";
import userPopup from "./Popup.vue";
import popover from "./Popover.vue";
import massUpdate from "@/components/common/MassUpdate";
// import userFilterMenu from "./Filter";
import { REPAIR_USER } from "../../../store/const/users";
const defaultFilter = {
  agencies: [],
  clients: [],
  orders: []
};
export default {
  components: {
    userPopup,
    popover,
    massUpdate
    // userFilterMenu,
  },
  mixins: [requestControl],
  props: {
    search: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showFilters: {
        name: false,
        agencies: true,
        clients: false,
        keeping_orders: false,
        watching_orders: false
      },
      selected: [],
      currentEntity: {},
      dialogState: false,
      footerProps: {
        "items-per-page-options": [5, 10, 20, 50, -1],
        "items-per-page-text": "Пользователей на странице:"
      },
      filters: JSON.parse(JSON.stringify(defaultFilter)),
      headers: [
        {
          text: "Название",
          align: "start",
          value: "name"
        },
        {
          text: "Роль",
          align: "start",
          value: "type.id"
        },
        {
          text: "E-mail",
          align: "start",
          value: "email"
        },
        {
          text: "Агентства",
          align: "start",
          width: 190,
          value: "agencies"
        },
        {
          text: "Клиенты",
          width: 190,
          align: "start",
          value: "clients"
        },
        {
          text: "Верифицирован",
          align: "start",
          width: 190,
          value: "email_verified_at"
        },
        {
          text: "Опции",
          align: "start",
          width: 190,
          value: "options",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapState("Users", ["users"]),
    ...mapGetters("Agencies", {
      agencies: "getOnlyAgencies"
    }),
    ...mapGetters("Clients", {
      clients: "getClients"
    }),

    filteringUsers() {
      const { agencies: a, clients: c, orders: o } = this.filters;
      if (a.length != 0 || c.length != 0 || o.length != 0) {
        return this.users.filter(user => {
          const uAgencies = user.agencies.map(agency => agency.name);
          const uClients = user.clients.map(client => client.name);
          const uOrders = user.agencies.map(order => order.name);
          if (
            a.length != 0 &&
            this.checkIntersections(a, uAgencies).length > 0
          ) {
            return true;
          }
          if (
            c.length != 0 &&
            this.checkIntersections(c, uClients).length > 0
          ) {
            return true;
          }
          if (o.length != 0 && this.checkIntersections(o, uOrders).length > 0) {
            return true;
          }
          return false;
        });
      }
      return this.users;
    },
    isLoading() {
      return (
        this.loading(GET_AGENCIES) === "loading" ||
        this.loading(GET_USERS) === "loading"
      );
    }
  },

  methods: {
    resetFilter() {
      this.filters = JSON.parse(JSON.stringify(defaultFilter));
    },
    checkIntersections(array1, array2) {
      return array1.filter(value => array2.includes(value));
    },
    ...mapActions("Agencies", {
      fetchAgencies: GET_AGENCIES
    }),
    // ...mapActions("Clients", {
    //   fetchClients: GET_CLIENTS,
    // }),
    ...mapActions("Roles", {
      fetchRoles: GET_ROLES
    }),
    ...mapActions("Users", {
      fetchUsers: GET_USERS,
      deleteUser: DELETE_USER,
      editStatus: UPDATE_USER,
      repairUser: REPAIR_USER
    }),
    async returnUser(user) {
      await this.repairUser(user);
      if (this.checkRequestOnError(REPAIR_USER)) {
        this.$notify({
          type: "succ",
          text: "Пользователь успешно восстановлен"
        });
        this.fetchUsers();
      }
    },
    async removeUser(user) {
      const res = await this.$confirm("Подтвердите удаление", {
        title: "Удаление"
      });
      if (res) {
        await this.deleteUser(user);
        if (this.checkRequestOnError(DELETE_USER)) {
          this.$notify({
            type: "succ",
            text: "Пользователь успешно удален"
          });
          this.fetchUsers();
        }
      }
    },
    async changeStatus(user) {
      let { id, is_active } = user;
      await this.editStatus({ id, data: { is_active: !is_active } });
      if (this.checkRequestOnError(UPDATE_USER)) {
        this.$notify({
          type: "succ",
          text:
            "Пользователь успешно " +
            (is_active ? "заблокирован" : "разблокирован")
        });
        this.fetchUsers();
      }
    },
    // formatUser(user) {
    //   const { agencies, clients, role } = user;
    //   return {
    //     ...user,
    //     agencies: agencies.map((agency) => agency.id),
    //     clients: clients.map((client) => client.id),
    //     role: role.id,
    //   };
    // },
    openDialog(user) {
      // let formatedUser = this.formatUser(user);
      this.currentEntity = user;
      this.dialogState = true;
    }
  }
};
</script>
