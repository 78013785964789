var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"#040b3e","overlay-opacity":"0.26","value":_vm.value,"width":"70%"},on:{"click:outside":function($event){return _vm.closeDialog()},"input":function($event){return _vm.$emit('input', !_vm.value)}},scopedSlots:_vm._u([(_vm.buttonText)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"rounded":"","block":_vm.$vuetify.breakpoint.smAndDown,"outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])]}}:null],null,true)},[_c('v-card',[_c('v-skeleton-loader',{staticClass:"mb-6",attrs:{"loading":_vm.isUserLoading,"boilerplate":"","type":"article, able-heading, list-item-two-line,  table-heading, divider,  actions, list-item-two-line, heading, list-item-two-line, actions"}},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.isNew ? "Добавление пользователя" : "Редактирование пользователя"))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();_vm.isNew ? _vm.createNewUser() : _vm.updateCurrentUser()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.rule.regularField,"label":"Имя пользователя *","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.rule.email,"label":"Email *","required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Роль *","items":_vm.types,"disabled":!_vm.isNew,"item-text":"description","item-value":"id","required":"","rules":_vm.rule.required},on:{"change":function($event){return _vm.updateType($event)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline")])]}}],null,true)}),_c('span',[_vm._v(_vm._s(item.description))])]}}]),model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)],1),_c('small',[_vm._v("* Помечены обязательные поля")]),_c('v-divider'),_c('v-expand-transition',[(_vm.form.type != 'Root')?_c('div',[_c('v-scroll-x-transition',{attrs:{"group":""}},_vm._l((_vm.accessBlocks),function(block,index){return _c('div',{key:index,attrs:{"transition":"fade-transition"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('client-agency-block',_vm._b({},'client-agency-block',_vm.accessBlocks[index],false,true))],1),_c('v-col',{staticClass:"align-end justify-start d-flex",attrs:{"cols":"11","md":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.accessBlocks.splice(index, 1)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)],1)}),0),_c('v-row',{staticClass:"ma-1"},[_c('v-btn',{attrs:{"block":"","color":"info"},on:{"click":_vm.addBlock}},[_vm._v(" Добавить Блок ")])],1)],1):_vm._e()])],1)],1),_c('v-card-actions',{staticClass:"mr-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Отмена ")]),(_vm.isNew)?_c('v-btn',{staticClass:"black--text",attrs:{"rounded":"","loading":_vm.isLoading,"color":"primary","disabled":!_vm.valid},on:{"click":function($event){return _vm.createNewUser()}}},[_vm._v(" Создать ")]):_c('v-btn',{staticClass:"black--text",attrs:{"rounded":"","loading":_vm.isLoading,"color":"primary","disabled":!_vm.valid},on:{"click":function($event){return _vm.updateCurrentUser()}}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }