<template>
  <v-expand-transition>
    <v-row
      transition="fade-transition"
      v-if="entityList.length != 0"
      class="primary"
    >
      <v-col class="d-flex flex-column justify-center"
        ><span class="text-normal ml-5">
          Выбрано {{ entityList.length }}</span
        ></v-col
      >
      <v-spacer></v-spacer>

      <v-col class="d-flex mr-4 justify-end">
        <v-btn
          data-cy="delete"
          v-if="entityListWithDisabled"
          @click="enableCheckedItems"
          icon="icon"
        >
          <v-icon color="black">mdi-eye</v-icon>
        </v-btn>
        <v-btn
          data-cy="delete"
          v-if="entityListWithActive"
          @click="disableCheckedItems"
          icon="icon"
        >
          <v-icon color="black">mdi-eye-off</v-icon>
        </v-btn>
        <v-btn data-cy="delete" @click="confirmDelete()" icon="icon">
          <v-icon color="black">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-expand-transition>
</template>

<script>
import { mapActions } from "vuex";
import { DELETE_USER, GET_USERS, UPDATE_USER } from "../../store/const/users";

export default {
  props: {
    entityList: {
      type: Array,
      required: true
    },
    parentEntityId: {
      // if component in binding page
      type: Number,
      required: false,
      default: -1
    }
  },

  data() {
    return {
      agency: "",
      stateOfDeleteDialog: false,
      editDialogActive: false,
      stateOfPopover: false
    };
  },
  computed: {
    entityListWithActive() {
      return this.entityList.filter(entity => entity.is_active).length > 0;
    },
    entityListWithDisabled() {
      return this.entityList.filter(entity => !entity.is_active).length > 0;
    }
  },
  methods: {
    ...mapActions("Users", {
      deleteCurrentUser: DELETE_USER,
      fetchUsers: GET_USERS,
      editStatus: UPDATE_USER
    }),

    async allSettledHandler(promisedArray) {
      let response = await Promise.allSettled(promisedArray);
      const rejected = response.filter(item => item.status !== "fulfilled");
      const confirmed = response.filter(item => item.status === "fulfilled");
      if (rejected.length == 0) {
        this.$notify({
          type: "succ",
          title: "Успешно"
        });
      } else {
        this.$notify({
          type: "err",
          title: "Ошибка",
          text:
            rejected.length > 1
              ? "Несколько элементов не было изменено"
              : "1 Элемент не был изменён"
        });
        if (confirmed.length > 0) {
          this.$notify({
            type: "succ",
            title: "Частично успешно"
          });
        }
      }
      await this.fetchUsers();
      this.$emit("update:entityList", []);
      return response;
    },

    async massChangeStatus(changeStatusFunc, entityList) {
      let promisedArray = new Array();
      for (const item of entityList) {
        promisedArray.push(changeStatusFunc(item));
      }
      return await this.allSettledHandler(promisedArray);
    },
    getIds(array) {
      return array.map(item => item.id);
    },
    editUserStatus(status) {
      return async user => {
        let { id } = user;
        await this.editStatus({ id, data: { is_active: status } });
      };
    },
    async enableCheckedItems() {
      const res = await this.$confirm("Подтвердите изменение статуса", {
        title: "Отображение"
      });
      if (res) {
        this.massChangeStatus(
          this.editUserStatus(true),
          this.entityList.filter(entity => !entity.is_active)
        );
      }
    },

    async disableCheckedItems() {
      const res = await this.$confirm("Подтвердите изменение статуса", {
        title: "Скрытие"
      });
      if (res) {
        this.massChangeStatus(
          this.editUserStatus(false),
          this.entityList.filter(entity => entity.is_active)
        );
      }
    },
    async confirmDelete() {
      const res = await this.$confirm("Подтвердите удаление", {
        title: "Удаление"
      });
      if (res) {
        this.massChangeStatus(this.deleteCurrentUser, this.entityList);
      }
    }
  }
};
</script>
