var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"sort-by":'name',"headers":_vm.headers,"items":_vm.filteringUsers,"items-per-page":10,"search":_vm.search,"fixed-header":"","loading":_vm.isLoading,"show-select":"","loader-height":"6","loading-text":"Загрузка списка... Пожалуйста подождите ","footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('mass-update',{staticClass:"ma-0",attrs:{"entity-list":_vm.selected},on:{"update:entityList":function($event){_vm.selected=$event},"update:entity-list":function($event){_vm.selected=$event}}})]},proxy:true},{key:"item.agencies",fn:function(ref){
var item = ref.item;
return [(item.agencies.length === 0)?_c('span',[_vm._v("-")]):_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(item.agencies[0].name))]),(item.agencies.length > 1)?_c('popover',{attrs:{"title":"Агентства","data":item,"main-prop":"agencies"}}):_vm._e()],1)]}},{key:"item.clients",fn:function(ref){
var item = ref.item;
return [(item.clients.length === 0)?_c('span',[_vm._v(" -")]):_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(item.clients[0].name))]),(item.clients.length > 1)?_c('popover',{attrs:{"title":"Клиенты","data":item,"main-prop":"clients"}}):_vm._e()],1)]}},{key:"item.email_verified_at",fn:function(ref){
var item = ref.item;
return [(!item.email_verified_at)?_c('span',[_vm._v("Не верифицирован")]):_c('span',[_vm._v(_vm._s(new Date(item.email_verified_at).toLocaleString()))])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"small":"","icon":"","fab":""},on:{"click":function($event){return _vm.openDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-lead-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактировать пользователя")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"small":"","icon":"","fab":""},on:{"click":function($event){return _vm.changeStatus(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.is_active ? "mdi-eye" : "mdi-eye-off"))])],1)]}}],null,true)},[_c('span',[_vm._v("Скрыть пользователя")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"small":"","icon":"","fab":""},on:{"click":function($event){item.deleted_at ? _vm.returnUser(item) : _vm.removeUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.deleted_at ? "mdi-delete-restore" : "mdi-delete"))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.deleted_at ? "Восстановить пользователя" : "Удалить пользователя"))])])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('user-popup',{attrs:{"is-new":false,"entity":_vm.currentEntity},model:{value:(_vm.dialogState),callback:function ($$v) {_vm.dialogState=$$v},expression:"dialogState"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }