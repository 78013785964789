<template>
  <v-dialog
    overlay-color="#040b3e"
    overlay-opacity="0.26"
    :value="value"
    @click:outside="closeDialog()"
    @input="$emit('input', !value)"
    width="70%"
  >
    <template v-if="buttonText" v-slot:activator="{ on, attrs }">
      <v-btn
        rounded
        :block="$vuetify.breakpoint.smAndDown"
        outlined
        v-bind="attrs"
        v-on="on"
      >
        {{ buttonText }}
      </v-btn>
    </template>
    <v-card>
      <v-skeleton-loader
        :loading="isUserLoading"
        class="mb-6"
        boilerplate
        type="article, able-heading, list-item-two-line,  table-heading, divider,  actions, list-item-two-line, heading, list-item-two-line, actions"
      >
        <v-card-title>
          <span>{{
            isNew ? "Добавление пользователя" : "Редактирование пользователя"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="isNew ? createNewUser() : updateCurrentUser()"
            v-model="valid"
            lazy-validation
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  :rules="rule.regularField"
                  label="Имя пользователя *"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.email"
                  :rules="rule.email"
                  label="Email *"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Роль *"
                  :items="types"
                  :disabled="!isNew"
                  item-text="description"
                  item-value="id"
                  v-model="form.type"
                  @change="updateType($event)"
                  required
                  :rules="rule.required"
                >
                  <template v-slot:prepend-item="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          mdi-help-circle-outline</v-icon
                        >
                      </template>
                    </v-tooltip>
                    <span>{{ item.description }}</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <small>* Помечены обязательные поля</small>
            <v-divider></v-divider>
            <v-expand-transition>
              <div v-if="form.type != 'Root'">
                <v-scroll-x-transition group>
                  <div
                    transition="fade-transition"
                    v-for="(block, index) in accessBlocks"
                    :key="index"
                  >
                    <v-row>
                      <v-col cols="12" md="11">
                        <client-agency-block
                          v-bind.sync="accessBlocks[index]"
                        ></client-agency-block>
                      </v-col>
                      <v-col
                        cols="11"
                        md="1"
                        class="align-end justify-start d-flex"
                      >
                        <v-btn icon @click="accessBlocks.splice(index, 1)"
                          ><v-icon>mdi-delete</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                </v-scroll-x-transition>
                <v-row class="ma-1">
                  <v-btn @click="addBlock" block color="info" class="">
                    Добавить Блок
                  </v-btn>
                </v-row>
              </div>
            </v-expand-transition>
          </v-form>
        </v-card-text>
        <v-card-actions class="mr-3">
          <v-spacer></v-spacer>
          <v-btn rounded text @click="closeDialog()">
            Отмена
          </v-btn>
          <v-btn
            rounded
            :loading="isLoading"
            v-if="isNew"
            color="primary"
            class="black--text"
            :disabled="!valid"
            @click="createNewUser()"
          >
            Создать
          </v-btn>
          <v-btn
            rounded
            :loading="isLoading"
            v-else
            color="primary"
            class="black--text"
            :disabled="!valid"
            @click="updateCurrentUser()"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import validationRules from "@/mixins/validationRules";
import requestControl from "@/mixins/requestControl";
import {
  CREATE_USER,
  UPDATE_USER,
  GET_USERS,
  GET_USER
} from "@/store/const/users";
import clientAgencyBlock from "./ClientAgencyBlock";
import { mapActions, mapGetters, mapState } from "vuex";
import { groupBy, parseObjectWithIdToString } from "../../../plugins/helpers";

let defaultForm = {
  name: "",
  email: "",
  type: "",
  agencies: [],
  clients: [],
  is_active: true
};

export default {
  components: {
    clientAgencyBlock
  },
  mixins: [validationRules, requestControl],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    buttonText: {
      type: String,
      required: false,
      default: undefined
    },
    entity: {
      type: Object,
      required: false,
      default: () => {}
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      form: JSON.parse(JSON.stringify(defaultForm)),
      valid: null,
      accessBlocks: [
        {
          agency: "",
          clients: []
        }
      ]
    };
  },
  computed: {
    ...mapState("Agencies", ["agencies"]),
    ...mapGetters("Roles", {
      types: "getRoles",
      typesByName: "getRolesIdsByNames"
    }),
    ...mapState("Users", ["currentUser"]),
    isLoading() {
      return (
        this.loading(CREATE_USER) === "loading" ||
        this.loading(UPDATE_USER) === "loading"
      );
    },
    isUserLoading() {
      return this.loading(GET_USER) === "loading";
    }
  },
  watch: {
    async entity(newValue) {
      await this.updatePopup(newValue);
    },
    async value(bool) {
      if (bool) {
        await this.updatePopup(this.entity);
      }
    }
  },
  methods: {
    ...mapActions("Users", {
      createUser: CREATE_USER,
      fetchUsers: GET_USERS,
      getUser: GET_USER,
      updateUser: UPDATE_USER
    }),
    async updatePopup(newValue) {
      if (newValue) {
        await this.getCurrentUser(newValue.id);
        let form = {};
        newValue = this.currentUser;
        this.accessBlocks = this.convertToAccessBlocks(newValue);
        for (const key in defaultForm) {
          if (Object.hasOwnProperty.call(defaultForm, key)) {
            form[key] = newValue[key];
          }
        }
        this.form = Object.assign({}, parseObjectWithIdToString(newValue));
      } else {
        this.accessBlocks = [
          {
            agency: "",
            clients: []
          }
        ];
        this.form = Object.assign({}, defaultForm);
      }
    },
    updateType(newVal) {
      if (newVal === "Root") {
        this.accessBlocks = [
          {
            agency: "",
            clients: []
          }
        ];
      }
    },
    addBlock() {
      this.accessBlocks.push({
        agency: "",
        clients: []
      });
    },
    setOneValue(value, formProp) {
      // костыль  делающий из multiple select обычный но с массвиом из одного элемента
      this.form[formProp] = [value[value.length - 1]];
    },
    convertFromAccessBlocks() {
      return {
        agencies: this.accessBlocks
          .filter(item => item.clients?.length === 0 && item.agency != "")
          .map(item => item.agency),
        clients: this.accessBlocks.map(item => item.clients).flat()
      };
    },
    convertToAccessBlocks(user) {
      let { agencies, clients } = user;
      clients = clients.filter(client => client.agency);
      let grouppedClients = groupBy(clients, client => client.agency.id);
      const accessBlocks = new Array();
      grouppedClients.forEach(clients => {
        accessBlocks.push({
          agency: clients[0].agency.id,
          clients: clients.map(client => client.id)
        });
      });
      agencies.forEach(agency => {
        accessBlocks.push({
          agency: agency.id,
          clients: []
        });
      });
      return accessBlocks;
    },
    async createNewUser() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        let agenciesAndClients = this.convertFromAccessBlocks();
        let form = {
          ...this.form,
          ...agenciesAndClients
        };
        await this.createUser(form);
        if (this.checkRequestOnError(CREATE_USER)) {
          this.$notify({
            type: "succ",
            text: "Пользователь успешно создан"
          });
          this.fetchUsers();
          this.closeDialog();
        }
      }
    },
    async getCurrentUser(id) {
      await this.getUser({ id });
      this.checkRequestOnError(GET_USER);
    },
    async updateCurrentUser() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        let agenciesAndClients = this.convertFromAccessBlocks();
        let form = {
          ...parseObjectWithIdToString(this.form),
          ...agenciesAndClients
        };
        await this.updateUser({ id: this.entity.id, data: form });
        if (this.checkRequestOnError(UPDATE_USER)) {
          this.$notify({
            type: "succ",
            text: "Пользователь успешно обновлен"
          });
          this.fetchUsers();
          this.closeDialog();
        }
      }
    },
    closeDialog() {
      this.$emit("input", false);
      // this.$refs.form.resetValidation();
      this.form = JSON.parse(JSON.stringify(defaultForm));
      // this.$refs.form.reset();
    }
  }
};
</script>
