<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-select
        dense
        :items="agencies"
        item-text="name"
        :value="agency"
        label="Агентство"
        @change="editAgency"
        item-value="id"
        hide-details=""
      ></v-select>
    </v-col>
    <v-col cols="12" md="6">
      <v-select
        dense
        label="Клиенты"
        multiple
        :items="clientsArray"
        @change="$emit('update:clients', $event)"
        item-text="name"
        :value="clients"
        item-value="id"
        hide-details=""
      >
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0">{{ item.name }}</span>
          <v-btn
            v-if="index === 1"
            class="ml-1"
            rounded
            small
            outlined
            color="primary"
            ><span class="black--text"> +{{ clients.length - 1 }}</span></v-btn
          >
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { GET_CLIENTS_BY_AGENCY } from "../../../store/const/clients";
export default {
  props: {
    agency: {
      type: [Number, String],
      required: true
    },
    clients: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      clientsArray: []
    };
  },
  computed: {
    ...mapState("Agencies", ["agencies"]),
    ...mapState("Clients", ["clientsMap"])
  },
  watch: {
    allClientsInAgency() {
      this.getClients(this.agency);
    },
    agency() {
      this.getClients(this.agency);
    }
  },
  created() {
    this.getClients(this.agency);
  },
  methods: {
    ...mapActions("Clients", {
      getClientsByAgency: GET_CLIENTS_BY_AGENCY
    }),
    async getClients(agencyId) {
      if (
        !isNaN(agencyId) &&
        agencyId !== "" &&
        this.clientsMap[agencyId] === undefined
      ) {
        await this.getClientsByAgency(agencyId);
      }
      this.clientsArray = this.clientsMap[agencyId.toString()];
      return 0;
    },
    async editAgency(agencyId) {
      await this.getClients(agencyId);

      this.$emit("update:clients", []);
      this.$emit("update:agency", agencyId);
    }
  }
};
</script>

<style></style>
