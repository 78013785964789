<template>
  <v-container
    class=" flex-nowrap flex-column"
    fluid="fluid"
    style="align-items: normal"
  >
    <view-toolbar title="Управление пользователями" v-model="search">
      <template #buttons>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-5"
              @click="showTrashedEntities = !showTrashedEntities"
              fab
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{
                showTrashedEntities ? "mdi-eye" : "mdi-eye-off"
              }}</v-icon>
            </v-btn>
          </template>
          <span>{{
            showTrashedEntities
              ? "Скрыть удаленные сущности"
              : "Показать удаленные сущности"
          }}</span>
        </v-tooltip>
        <users-popup
          button-text="Добавить пользователя"
          :is-new="true"
          v-model="dialogState"
        >
        </users-popup>
      </template>
    </view-toolbar>
    <v-row justify="center">
      <v-col cols="12">
        <v-skeleton-loader
          :loading="loading"
          transition="scale-transition"
          width="100%"
          type="table"
        >
          <users-table
            :show-trashed="showTrashedEntities"
            :search="search"
          ></users-table>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import viewToolbar from "@/components/common/ViewToolbar";
import usersPopup from "@/components/management/users/Popup.vue";
import usersTable from "@/components/management/users/Table.vue";
import { mapActions, mapGetters } from "vuex";
import { GET_AGENCIES } from "../../../store/const/agencies";
import { GET_ROLES } from "../../../store/const/roles";
import { GET_USERS } from "../../../store/const/users";
import { GET_CLIENTS } from "../../../store/const/clients";

export default {
  components: {
    viewToolbar,
    usersTable,
    usersPopup
  },
  data() {
    return {
      loading: false,
      dialogState: false,
      search: ""
    };
  },
  computed: {
    ...mapGetters("Auth", ["getUser"]),
    showTrashedEntities: {
      get() {
        return this.$store.state.Helpers.showTrashedEntities;
      },
      set(newVal) {
        this.$store.commit("Helpers/setShowTrashedEntities", newVal);
        this.fetchUsers();
      }
    },
    currentUserRole() {
      const {
        type: { id }
      } = this.getUser;
      return id.toLowerCase();
    },
    fetchUsersRules() {
      return ["root", "manager"].includes(this.currentUserRole);
    }
  },
  async created() {
    this.fetchAgencies();
    this.fetchUsersRules && this.fetchUsers();
    this.fetchRoles();
    this.fetchClients();
    // this.fetchClients();
  },
  methods: {
    ...mapActions("Users", {
      fetchUsers: GET_USERS
    }),
    ...mapActions("Agencies", {
      fetchAgencies: GET_AGENCIES
    }),
    ...mapActions("Clients", {
      fetchClients: GET_CLIENTS
    }),

    ...mapActions("Roles", {
      fetchRoles: GET_ROLES
    })
  }
};
</script>
